import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import isPast from 'date-fns/isPast';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import resultDate from './result-date';
import ResultKeywords from './result-keywords';

const eventDate = (result) => {
  const { startDate } = result;
  if (!startDate) {
    return resultDate(result);
  }
  const diffInDays = differenceInDays(new Date(), new Date(startDate));
  const showFormattedDate = Math.abs(diffInDays) > 14;
  const dayOrDays = Math.abs(diffInDays) === 1 ? 'day' : 'days';
  if (isPast(new Date(startDate))) {
    if (showFormattedDate) {
      return `Held on ${format(new Date(startDate), 'do MMM yyyy')}`;
    }
    return `Held ${diffInDays} ${dayOrDays} ago`;
  }
  if (showFormattedDate) {
    return (
      <Text as="strong" display="inline">
        Starts {format(new Date(startDate), 'do MMM yyyy')}.{' '}
        <CanonicalLink
          color="tt.darkBlue"
          to={`/events/${result.slug}/#get-tickets`}
        >
          Book your place now!
        </CanonicalLink>
      </Text>
    );
  }
  return `Starting in ${diffInDays} ${dayOrDays}`;
};

const EventSearchResult = ({ result, ...props }) => (
  <Box as="aside" {...props}>
    <Heading as="h1" fontSize="lg" mb={2}>
      <CanonicalLink
        to={`/events/${result.slug}`}
        title={result.title}
        dangerouslySetInnerHTML={{
          __html: result._highlightResult.title.value,
        }}
      />
    </Heading>
    <Box>
      <Text as="strong" display="inline">
        Event
      </Text>
      , {result.type}. {eventDate(result)}
    </Box>
    <Html source={result._highlightResult.excerpt.value} headerLevelStart={1} />
    <ResultKeywords
      resultId={result.id}
      keywords={result.metaKeywords}
      highlightKeywords={result._highlightResult.metaKeywords}
    />
  </Box>
);

export default EventSearchResult;
