import React, { useEffect, useState, useRef } from 'react';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import * as Sentry from '@sentry/browser';
import { useLocation } from '@reach/router';
import { Box, Container, Text, VStack } from '@chakra-ui/react';
import chunk from 'lodash/chunk';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { KnowledgebaseLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Pagination from 'timelesstime-ui/components/pagination';
import LoadingHeadSpinner from 'timelesstime-ui/components/loading-head-spinner';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import KnowledgebaseSearch from '../components/knowledgebase-search';
import KnowledgebaseSearchResult from '../components/search/knowledgebase-search-result';
import EventSearchResult from '../components/search/event-search-result';
import CaseStudySearchResult from '../components/search/case-study-search-result';
import ServiceSearchResult from '../components/search/service-search-result';
import SearchCallToAction from '../components/search/search-call-to-action';
import JSONStringifyParam from '../utils/use-query-params-json-stringify';

const HITS_PER_PAGE = 20;

// dont destructure these - https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#example-of-using-an-environment-variable
const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_SEARCH_KEY,
);
const searchIndex = searchClient.initIndex(process.env.ALGOLIA_INDEX_NAME);

const searchAttributesToRetrieve = [
  '___type',
  'id',
  'slug',
  'title',
  'excerpt',
  'metaDescription',
  'metaKeywords',
  'knowledgeType',
  'createdAt',
  'updatedAt',
  'serviceType', // for case studies
  'startDate', // for events
  'type', // for events
];

const highlightPreTag = '<em class="matched">';
const highlightPostTag = '</em>';

const emptyResults = Object.freeze({ hits: [] });

const searchResultComponents = {
  service: (searchHit) => <ServiceSearchResult result={searchHit} />,
  event: (searchHit) => <EventSearchResult result={searchHit} />,
  'case-study': (searchHit) => <CaseStudySearchResult result={searchHit} />,
  knowledge: (searchHit) => <KnowledgebaseSearchResult result={searchHit} />,
};

const calculateUrlForQueryParams = ({ location, query, filters, page }) => {
  const urlParams = new URLSearchParams(location.search);
  if (typeof query !== 'undefined') {
    if (query) {
      urlParams.set('query', query);
    } else {
      urlParams.delete('query');
    }
  }
  if (typeof filters !== 'undefined') {
    if (filters && filters.length) {
      urlParams.set('filters', filters.join(','));
    } else {
      urlParams.delete('filters');
    }
  }
  if (typeof page !== 'undefined') {
    if (page && page > 1) {
      urlParams.set('page', page);
    } else {
      urlParams.delete('page');
    }
  }
  const urlParamsString = urlParams.toString();
  const urlQueryString =
    typeof urlParamsString !== 'undefined' && urlParamsString !== ''
      ? `?${urlParamsString}`
      : '';
  return `${location.pathname}${urlQueryString}`;
};

const doAlgoliaSearch = async ({
  query,
  searchFingerprint,
  activeFingerprintRef,
  filters,
  page,
  setIsLoading,
  setSearchError,
  setSearchResults,
}) => {
  if (
    searchFingerprint !== activeFingerprintRef.current &&
    activeFingerprintRef.current !== undefined
  ) {
    return;
  }
  setSearchError(false);
  if (typeof query === 'undefined' || query === '') {
    setSearchResults(emptyResults);
  } else {
    setIsLoading(true);
    const { query: algoliaQuery, tags } = query.split(' ').reduce(
      ({ query: q, tags: t }, term) =>
        term.startsWith('#')
          ? {
              query: q,
              tags: [...t, `metaKeywords:${term.substring(1)}`],
            }
          : {
              query: `${q} ${term}`.trim(),
              tags: t,
            },
      { query: '', tags: [] },
    );
    try {
      const algoliaSearchResult = await searchIndex.search(algoliaQuery, {
        facetFilters: [filters.map((field) => `___type:${field}`).sort(), tags],
        hitsPerPage: HITS_PER_PAGE,
        page: page - 1 || 0, // algolia pages are 0 indexed
        highlightPreTag,
        highlightPostTag,
        attributesToRetrieve: searchAttributesToRetrieve,
        attributesToHighlight: searchAttributesToRetrieve,
      });
      if (
        !!activeFingerprintRef.current &&
        activeFingerprintRef.current !== searchFingerprint
      ) {
        return;
      }
      if (algoliaSearchResult && algoliaSearchResult) {
        setSearchResults(algoliaSearchResult);
      } else {
        setSearchResults(emptyResults);
      }
    } catch (e) {
      Sentry.captureException(e);
      setSearchResults(emptyResults);
      setSearchError(true);
    } finally {
      setIsLoading(false);
    }
  }
};

const SearchResultsContent = ({
  isLoading,
  query,
  searchHits,
  hasSearchError,
}) => {
  if (isLoading) {
    return <LoadingHeadSpinner text="Searching" />;
  }
  if (!query) {
    return (
      <Box>
        <Heading as="h1">What are you looking for?</Heading>
        <Text>
          Search for something in the box above and we&apos;ll have a look for
          any related knowledge we have.
        </Text>
      </Box>
    );
  }

  const resultsComponents = searchHits.reduce((carry, searchHit) => {
    const searchHitType = searchHit.___type.split(':')[0];
    const searchComponent = searchResultComponents[searchHitType];
    if (typeof searchComponent !== 'function') {
      return carry;
    }
    return [
      ...carry,
      <Box key={searchHit.id}>{searchComponent(searchHit)}</Box>,
    ];
  }, []);
  const resultsComponentsWithCallToActions = chunk(resultsComponents, 7).reduce(
    (carry, components, index) =>
      index >= 1
        ? [...carry, <SearchCallToAction key={`cta-${index}`} />, ...components]
        : [...carry, ...components],
    [],
  );

  if (resultsComponentsWithCallToActions.length > 0) {
    return <VStack spacing={8}>{resultsComponentsWithCallToActions}</VStack>;
  }
  if (hasSearchError) {
    return (
      <Box>
        <Heading as="h1">Oops, there was an error while searching.</Heading>
        <Text mt={4}>
          Please try again later or{' '}
          <CanonicalLink to="/contact/">get in touch</CanonicalLink>, we&apos;d
          love to help you.
        </Text>
      </Box>
    );
  }
  return (
    <Box>
      <Heading as="h1">Oops, it looks like nothing was found.</Heading>
      <Text mt={4}>
        That doesn&apos;t mean all hope is lost.{' '}
        <CanonicalLink to="/contact/">Get in touch</CanonicalLink>, we&apos;d
        love to help you.
      </Text>
    </Box>
  );
};

const getFiltersText = (filters) => {
  if (filters.length <= 1) {
    return filters.join(', ');
  }
  const lastFilter = filters.slice(-1)[0];
  return `${filters.slice(0, -1).join(', ')} & ${lastFilter}`;
};

const SearchPage = ({ data: { searchPage } }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    query: StringParam,
    filter: JSONStringifyParam,
    page: NumberParam,
  });
  const { query, filter: filtersParam, page } = queryParams;

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [searchFingerprint, setSearchFingerprint] = useState({
    fingerprint: '',
  });
  const [hasSearchError, setSearchError] = useState(false);
  const [searchResults, setSearchResults] = useState(emptyResults);
  const activeFingerprintRef = useRef();
  const setNewQueryFingerprint = ({
    query: newQuery,
    filter: newFilter,
    page: newPage,
  }) => {
    const fingerprint = btoa(`${newQuery}.${newFilter}.${newPage}`);
    activeFingerprintRef.current = fingerprint;
    setSearchFingerprint(fingerprint);
  };

  const filters =
    !!filtersParam && Array.isArray(filtersParam) ? filtersParam : [];
  const { hits: searchHits } = searchResults;

  useEffect(() => {
    doAlgoliaSearch({
      query,
      // pass fingerprint by value AND the object so they can be used for XHR ordering
      activeFingerprintRef,
      searchFingerprint,
      filters,
      page,
      setIsLoading,
      setSearchError,
      setSearchResults: (results) => {
        setSearchResults(results);
        setIsLoading(false);
      },
    });
  }, [location.href, searchFingerprint]);

  return (
    <PageLayout
      leadInHeader={
        <KnowledgebaseLeadInHeader
          heading={searchPage.heading || searchPage.title}
          showSearch={false}
          crumbs={[
            {
              url: '/search/',
              title: searchPage.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={searchPage.title}
        keywords={searchPage.fields.keywords}
        description={searchPage.metaDescription}
        canonicalPath="/search/"
        thumbnail={searchPage.jsonLdThumbnailImage}
      />

      <Container maxW="container.lg" mt={2} mb={4}>
        <KnowledgebaseSearch
          query={query}
          filters={filters}
          handleSearch={(searchQuery, newFilters) => {
            const newQueryParams = {
              query: searchQuery.length < 1 ? undefined : searchQuery,
              filter:
                newFilters && newFilters.length > 0 ? newFilters : undefined,
              page: undefined,
            };
            setQueryParams(newQueryParams);
            setNewQueryFingerprint(newQueryParams);
          }}
        />
      </Container>

      <Container maxW="container.lg" mt={2} mb={8}>
        <VStack spacing={1} alignItems="flex-start">
          {query && (
            <Box>
              Searching for{' '}
              <Text as="i" disable="inline">
                {query}
              </Text>
            </Box>
          )}
          {filters.length > 0 && <Box>{getFiltersText(filters)}</Box>}
          {!!searchResults.nbHits && (
            <Box>
              Found {searchResults.nbHits} results. Showing page{' '}
              {searchResults.page + 1} of {searchResults.nbPages}.
            </Box>
          )}
        </VStack>
      </Container>

      <Container maxW="container.lg" mt={4} mb={4}>
        <Html source={searchPage.fields.contentHtml} headerLevelStart={1} />
      </Container>

      <Container as="section" maxW="container.lg" mt={4} mb={4}>
        <Box mt={2} mb={8}>
          <SearchResultsContent
            isLoading={isLoading}
            query={query}
            searchHits={searchHits}
            hasSearchError={hasSearchError}
          />
        </Box>

        {/* searchResults.page is 0 indexed */}
        <Pagination
          mt={12}
          mb={12}
          currentPage={searchResults.page + 1}
          numPages={searchResults.nbPages}
          firstPageUrl={calculateUrlForQueryParams({
            location,
            page: '',
          })}
          prevPageUrl={calculateUrlForQueryParams({
            location,
            page: searchResults.page,
          })}
          nextPageUrl={calculateUrlForQueryParams({
            location,
            page: searchResults.page + 2,
          })}
          lastPageUrl={calculateUrlForQueryParams({
            location,
            page: searchResults.nbPages,
          })}
          onClick={(event, url) => {
            const urlParser = document.createElement('a');
            urlParser.href = url;
            const urlParams = new URLSearchParams(urlParser.search);
            const newPage = parseInt(urlParams.get('page'), 10);
            setQueryParams({
              page: newPage,
            });
            setNewQueryFingerprint({
              query,
              filter: filtersParam,
              page: newPage,
            });
            window.scrollTo(0, 0);
          }}
        />

        <Box as="footer" textAlign="center" my={12}>
          <CallMeBack
            size="lg"
            callBackText="Do you want help from our team of experts? Get in touch"
          />
        </Box>
      </Container>

      <Jumbotron as="aside" py={8} bg="tt.darkBlue">
        <NewsletterSignup />
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query SearchPageQuery {
    searchPage: contentfulPage(slug: { eq: "search" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
  }
`;

export default SearchPage;
