import React from 'react';
import {
  Flex,
  Box,
  Text,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import useLoadingSpinnerImages from 'timelesstime-gatsby-utils/hooks/useLoadingSpinnerImages';

const iconSpin = keyframes`
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export default ({ text = 'loading', ...props }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const { timelessTimeHead, timelessTimeHands } = useLoadingSpinnerImages();
  return (
    <Flex
      marginX="20px"
      marginY="50px"
      color="gray.800"
      alignItems="center"
      flexDir="column"
      {...props}
    >
      <Box
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        width="140px"
        height="140px"
        display="inline-block"
        position="relative"
        z-index="-1"
        backgroundImage={`url(${timelessTimeHead.childImageSharp.gatsbyImageData.placeholder.fallback})`}
      >
        <Box
          backgroundRepeat="no-repeat"
          backgroundPosition="30px 6px"
          backgroundSize="50px 50px"
          width="100px"
          height="100px"
          display="inline-block"
          position="absolute"
          left="19px"
          top="8px"
          animation={
            prefersReducedMotion ? undefined : `${iconSpin} 3s infinite linear`
          }
          backgroundImage={`url(${timelessTimeHands.childImageSharp.gatsbyImageData.placeholder.fallback})`}
        />
      </Box>
      <Text fontSize="xl">{text}</Text>
    </Flex>
  );
};
