import { useStaticQuery, graphql } from 'gatsby';

const useLoadingSpinnerImages = () => {
  const images = useStaticQuery(graphql`
    query useLoadingSpinnerImages {
      timelessTimeHead: file(name: { eq: "TimelessTime-Head" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, blurredOptions: { width: 140 })
        }
      }
      timelessTimeHands: file(name: { eq: "TimelessTime-Hands" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, blurredOptions: { width: 100 })
        }
      }
    }
  `);
  return images;
};

export default useLoadingSpinnerImages;
