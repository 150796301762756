import React from 'react';
import { HStack, Box, Button } from '@chakra-ui/react';
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from 'react-icons/fa';
import ButtonLink from '../button-link';

const PageLink = ({
  children,
  page,
  currentPage,
  numPages,
  url,
  label,
  onClick,
  ...props
}) => {
  if (page === currentPage || page < 1 || page > numPages) {
    return (
      <Button
        variant="outline"
        aria-label={label}
        borderColor="gray.200"
        disabled
        _disabled={{ opacity: 1, color: 'gray.300' }}
        {...props}
      >
        {children}
      </Button>
    );
  }
  return (
    <ButtonLink
      borderColor="gray.200"
      aria-label={label}
      variant="outline"
      color="tt.darkBlue"
      onClick={(event) => {
        if (typeof onClick === 'function') {
          event.preventDefault();
          onClick(event, url);
        }
      }}
      to={url}
      {...props}
    >
      {children}
    </ButtonLink>
  );
};

const OL = ({ ...props }) => <Box as="ol" display="inline" {...props} />;
const LI = ({ ...props }) => <Box as="li" display="inline" {...props} />;

const Pagination = ({
  currentPage = 1,
  numPages = 0,
  firstPageUrl = '',
  prevPageUrl = '',
  nextPageUrl = '',
  lastPageUrl = '',
  onClick = undefined,
  ...props
}) => {
  if (numPages < 1) {
    return null;
  }
  return (
    <HStack as="nav" aria-label="pagination" justifyContent="center" {...props}>
      <OL>
        <LI>
          <PageLink
            onClick={onClick}
            currentPage={currentPage}
            numPages={numPages}
            page={1}
            url={firstPageUrl}
            label="First Page"
            borderRight="0"
            borderRightRadius="0"
          >
            <FaAngleDoubleLeft />
          </PageLink>
        </LI>
        <LI>
          <PageLink
            onClick={onClick}
            currentPage={currentPage}
            numPages={numPages}
            page={currentPage - 1}
            url={prevPageUrl}
            rel="prev"
            label="Previous Page"
            borderRight="0"
            borderLeftRadius="0"
            borderRightRadius="0"
          >
            <FaAngleLeft />
          </PageLink>
        </LI>
        <LI>
          <PageLink
            onClick={onClick}
            currentPage={currentPage}
            numPages={numPages}
            page={currentPage}
            url="#"
            borderRight="0"
            borderLeftRadius="0"
            borderRightRadius="0"
          >
            Page {currentPage} of {numPages}
          </PageLink>
        </LI>
        <LI>
          <PageLink
            onClick={onClick}
            currentPage={currentPage}
            numPages={numPages}
            page={currentPage + 1}
            url={nextPageUrl}
            rel="next"
            label="Next Page"
            borderRight="0"
            borderLeftRadius="0"
            borderRightRadius="0"
          >
            <FaAngleRight />
          </PageLink>
        </LI>
        <LI>
          <PageLink
            onClick={onClick}
            currentPage={currentPage}
            numPages={numPages}
            page={numPages}
            url={lastPageUrl}
            label="Last Page"
            borderLeftRadius="0"
          >
            <FaAngleDoubleRight />
          </PageLink>
        </LI>
      </OL>
    </HStack>
  );
};

export default Pagination;
