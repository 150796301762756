import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import resultDate from './result-date';
import ResultKeywords from './result-keywords';
import knowledgeType from '../../utils/knowledge-type';

const KnowledgebaseSearchResult = ({ result, ...props }) => (
  <Box as="aside" {...props}>
    <Heading as="h1" fontSize="lg" mb={2}>
      <CanonicalLink
        to={`/knowledgebase/${result.slug}`}
        title={result.title}
        dangerouslySetInnerHTML={{
          __html: result._highlightResult.title.value,
        }}
      />
    </Heading>
    <Box>
      <Text as="strong" display="inline">
        {knowledgeType(result.knowledgeType)}
      </Text>
      . {resultDate(result)}
    </Box>
    <Html source={result._highlightResult.excerpt.value} headerLevelStart={1} />
    <ResultKeywords
      resultId={result.id}
      keywords={result.metaKeywords}
      highlightKeywords={result._highlightResult.metaKeywords}
    />
  </Box>
);

export default KnowledgebaseSearchResult;
