import React, { useState, useEffect, useCallback } from 'react';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import ReactGA from 'react-ga';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Box,
  HStack,
  Text,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Flex,
} from '@chakra-ui/react';
import FilterToggles from 'timelesstime-ui/components/filter-toggles';

const GACategory = 'search';

const searchToggles = [
  {
    name: 'knowledge:article',
    label: 'Articles',
  },
  {
    name: 'knowledge:white paper',
    label: 'White Papers',
  },
  {
    name: 'knowledge:opinion',
    label: 'Blog Posts',
  },
  {
    name: 'knowledge:audio visual',
    label: 'Videos',
  },
  {
    name: 'case-study',
    label: 'Case Studies',
  },
  {
    name: 'service',
    label: 'Services',
  },
  {
    name: 'event',
    label: 'Events',
  },
];
const allFilterNames = searchToggles.map((t) => t.name);

const SearchInput = ({ query, onChange, onButtonClicked }) => {
  const [isActive, setActive] = useState(false);
  const [value, setValue] = useState(query);
  useEffect(() => {
    if (!isActive) {
      setValue(query);
    }
  }, [query]);
  return (
    <InputGroup>
      <Input
        type="search"
        aria-label="Site Search"
        size="100"
        value={isActive ? value : query}
        px={2}
        py={1}
        onChange={(event) => {
          const { value: targetValue } = event.target;
          setValue(targetValue);
          onChange(targetValue);
        }}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        variant="outline"
        color="gray.900"
        bg="white"
        borderTopLeftRadius={4}
        borderBottomLeftRadius={4}
      />
      <InputRightAddon px={0} bg="transparent" border="none">
        <Button
          borderLeftRadius={0}
          colorScheme="orange"
          onClick={onButtonClicked}
        >
          Go!
        </Button>
      </InputRightAddon>
    </InputGroup>
  );
};

export default ({ query = '', filters = [], handleSearch: doHandleSearch }) => {
  const { algoliaLogo } = useStaticQuery(graphql`
    query KnowledgebaseSerachQuery {
      algoliaLogo: file(name: { eq: "Algolia-logo" }) {
        name
        childImageSharp {
          gatsbyImageData(
            width: 83
            height: 22
            quality: 100
            placeholder: NONE
            layout: FIXED
          )
        }
      }
    }
  `);

  const [showFilterToggles, setShowFilterToggles] = useState(
    typeof filters !== 'undefined' && filters.length > 0,
  );

  const handleReset = useCallback(() => {
    doHandleSearch('', []);
    ReactGA.event({
      category: GACategory,
      action: 'reset',
    });
  });

  const handleClearFilters = useCallback(() => {
    doHandleSearch(query, []);
    ReactGA.event({
      category: GACategory,
      action: 'clear filters',
    });
  });

  const handleChangeFilter = useCallback((filterType) => {
    let newFilters;
    if (filters.length === 0) {
      // allFiltersButFilterType
      newFilters = allFilterNames.filter((type) => type !== filterType);
    } else if (filters.includes(filterType)) {
      newFilters = filters.filter((type) => type !== filterType);
    } else {
      newFilters = [...filters, filterType];
    }
    doHandleSearch(query, newFilters);
    ReactGA.event({
      category: GACategory,
      action: 'filter',
      value: newFilters.join(','),
    });
  });

  const handleSearch = (search) => {
    doHandleSearch(search, filters);
    ReactGA.event({
      category: GACategory,
      action: 'search',
      value: search,
    });
  };

  const handleSearchThrottled = useThrottledCallback(
    handleSearch,
    [doHandleSearch],
    500,
  );
  const handleSearchDebounced = useDebouncedCallback(
    handleSearch,
    [doHandleSearch],
    500,
  );
  const handleSearchInputChanged = useCallback((value) => {
    if (value.length < 5) {
      handleSearchThrottled(value);
    } else {
      handleSearchDebounced(value);
    }
  });

  const handleGo = useCallback(() => {
    handleSearchThrottled.flush();
    handleSearchDebounced.flush();
    ReactGA.event({
      category: GACategory,
      action: 'search-button',
    });
  });

  const toggles = searchToggles.map(({ ...toggle }) => ({
    ...toggle,
    toggles: filters,
    onChange: useCallback(() => handleChangeFilter(toggle.name)),
  }));
  const onShowFilterToggles = useCallback(() => setShowFilterToggles(true));
  const onHideFilterToggles = useCallback(() => setShowFilterToggles(false));

  return (
    <Box>
      <HStack spacing={1}>
        <Text flexShrink="0" display={['block', 'none']}>
          Search:
        </Text>
        <Text flexShrink="0" display={['none', 'block', 'none']}>
          Tell me about:
        </Text>
        <Text flexShrink="0" display={['none', 'none', 'block']}>
          I want to know about:
        </Text>
        <SearchInput
          query={query}
          onChange={handleSearchInputChanged}
          onButtonClicked={() => handleGo()}
        />
        <Button
          variant="unstyled"
          color="orange.500"
          colorScheme="orange"
          onClick={(e) => {
            e.preventDefault();
            handleReset();
          }}
          aria-label="reset search"
        >
          reset
        </Button>
      </HStack>

      <Box as="section" mt={2} mb={8} maxW="container.lg">
        <FilterToggles>
          <FilterToggles.ShowHideRow
            show={showFilterToggles}
            showText="Filter search results"
            hideText="Hide search result filters"
            onShow={onShowFilterToggles}
            onHide={onHideFilterToggles}
          >
            <Flex alignItems="center">
              <Box pr={2}>Search powered by </Box>
              <Box pr={2}>
                <GatsbyImage
                  image={getImage(algoliaLogo)}
                  alt={algoliaLogo.name}
                />
              </Box>
            </Flex>
          </FilterToggles.ShowHideRow>
          <FilterToggles.TogglesCollapse show={showFilterToggles}>
            <FilterToggles.TogglesBox
              description="Filter search results by:"
              onReset={handleClearFilters}
              resetText="clear filters"
              toggles={toggles}
            />
          </FilterToggles.TogglesCollapse>
        </FilterToggles>
      </Box>
    </Box>
  );
};
