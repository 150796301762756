import React from 'react';
import { HStack } from '@chakra-ui/react';

import zip from 'lodash/zip';
import uniqBy from 'lodash/uniqBy';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';

const ResultKeywords = ({ resultId, keywords, highlightKeywords }) => (
  <HStack spacing={2} wrap="wrap">
    {uniqBy(zip(keywords, highlightKeywords), ([keyword]) => keyword).map(
      ([keyword, { value: highlightResult }]) => (
        <CanonicalLink
          fontSize="sm"
          color="gray.500"
          sx={{
            '.matched': {
              color: 'tt.midBlue',
            },
          }}
          key={`${resultId}-${keyword.replace('-', '--').replace(' ', '-')}`}
          to={`/search?query=${encodeURIComponent(
            '"',
          )}${keyword}${encodeURIComponent('"')}`}
          dangerouslySetInnerHTML={{
            __html: `#${highlightResult}`,
          }}
        />
      ),
    )}
  </HStack>
);

export default ResultKeywords;
