import React from 'react';
import { Box } from '@chakra-ui/react';
import CallMeBack from 'timelesstime-ui/components/call-me-back';

const SearchCallToAction = () => (
  <Box pt={8} pb={4}>
    <CallMeBack
      size="lg"
      callBackText="Do you want help from our team of experts? Get in touch"
    />
  </Box>
);

export default SearchCallToAction;
